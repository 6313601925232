import http from "./http";
export default (incomeId) => ({
  async index(filters) {
    return http
      .index("incomes", filters)
      .then((json) => ({ incomes: json }))
      .catch(async (e) => ({ incomes: null, error: await e }));
  },
  async get() {
    return http
      .get("incomes", incomeId)
      .then((json) => ({ income: json }))
      .catch(async (e) => ({ income: null, error: await e }));
  },
  async create(data) {
    return http
      .post("incomes", data)
      .then((json) => ({ income: json }))
      .catch(async (e) => ({ income: null, error: await e }));
  },
  async update(data) {
    return http
      .put("incomes", incomeId, data)
      .then((json) => ({ income: json }))
      .catch(async (e) => ({ income: null, error: await e }));
  },
  async delete() {
    return http
      .delete("incomes", incomeId)
      .then((json) => ({ income: json }))
      .catch(async (e) => ({ income: null, error: await e }));
  },
  Products: (productId) => ({
    async index(filters) {
      return http
        .index(`incomes/${incomeId}/products`, filters)
        .then((json) => ({ products: json }))
        .catch(async (e) => ({ products: null, error: await e }));
    },
    async get() {
      return http
        .get(`incomes/${incomeId}/products`, productId)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`incomes/${incomeId}/products`, data)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`incomes/${incomeId}/products`, productId, data)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`incomes/${incomeId}/products`, productId)
        .then((json) => ({ product: json }))
        .catch(async (e) => ({ product: null, error: await e }));
    },
  }),
  Observations: (obsId) => ({
    async index(filters) {
      return http
        .index(`incomes/${incomeId}/obs`, filters)
        .then((json) => ({ observations: json }))
        .catch(async (e) => ({ observations: null, error: await e }));
    },
    async get() {
      return http
        .get(`incomes/${incomeId}/obs`, obsId)
        .then((json) => ({ observation: json }))
        .catch(async (e) => ({ observation: null, error: await e }));
    },
    async create(data) {
      return http
        .post(`incomes/${incomeId}/obs`, data)
        .then((json) => ({ observation: json }))
        .catch(async (e) => ({ observation: null, error: await e }));
    },
    async update(data) {
      return http
        .put(`incomes/${incomeId}/obs`, obsId, data)
        .then((json) => ({ observation: json }))
        .catch(async (e) => ({ observation: null, error: await e }));
    },
    async delete() {
      return http
        .delete(`incomes/${incomeId}/obs`, obsId)
        .then((json) => ({ observation: json }))
        .catch(async (e) => ({ observation: null, error: await e }));
    },
  }),
  Logs: () => ({
    async index() {
      return http
        .index(`incomes/${incomeId}/logs`)
        .then((json) => ({ logs: json }))
        .catch(async (e) => ({ logs: null, error: await e }));
    },
  }),
});
